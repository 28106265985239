import aiea from "../assets/images/locations/aiea.jpg";
import kailuaNew from "../assets/images/locations/kailuaNew.jpg";
import kalakaua from "../assets/images/locations/kalakaua.jpg";
import kapolei from "../assets/images/locations/kapolei.jpg";
import saratoga from "../assets/images/locations/saratoga.jpg";
import waikiki from "../assets/images/locations/waikiki.jpg";
import story from "../assets/images/storyMain.jpg";

const locationsList = [
  {
    title: "Sacred Art Tattoo Kailua",
    pic: kailuaNew,
    description: "167 Hamakua Dr, Kailua, HI",
    link: "https://sacredartkailua.com/",
  },
  {
    title: "Sacred Art Tattoo",
    pic: waikiki,
    description: "1831 Ala Moana Blvd, #204, Honolulu, HI",
    link: "https://sacredartwaikiki.com/",
  },
  {
    title: "Sacred Art Tattoo & Coffee Bar",
    pic: kalakaua,
    description: "1925 Kalakaua Ave, Unit A, Honolulu, HI",
    link: "https://sacredarthonolulu.com/",
  },
  {
    title: "Sacred Art Tattoo on Saratoga",
    pic: saratoga,
    description: "339 Saratoga Rd, Honolulu, HI",
    link: "https://sacredartsaratoga.com/",
  },
  {
    title: "Sacred Art Tattoo Headquarters",
    pic: story,
    description: "1831 Ala Moana Blvd, #205, Honolulu, HI",
    link: "https://sacredarthawaii.com/",
    border: "yes",
  },
  {
    title: "Sacred Art Tattoo & Coffee Bar",
    pic: kapolei,
    description: "563 Farrington Hwy #208, Kapolei, HI",
  },
  {
    title: "Sacred Art Tattoo Aiea, formerly known as TNT Tattoo",
    pic: aiea,
    description: "99 Kamehameha Hwy, Aiea, HI",
    link: "https://tattooshawaii.com/",
  },
];

export default locationsList;
